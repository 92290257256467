import Axios from "axios";
import emailService from "../services/EmailService";

let apiUrl = "http://127.0.0.1:8000/";
// let apiUrl = 'http://172.18.0.5:8000/'

// Axios Configuration
Axios.defaults.headers.common.Accept = "application/json";
Axios.defaults.headers.common["Content-Type"] = "application/json";
Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  emailService: new emailService(Axios, apiUrl),
};
