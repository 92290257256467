import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { VLazyImagePlugin } from "v-lazy-image";
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFbCustomerChat from 'vue-fb-customer-chat'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false
Vue.use(VLazyImagePlugin);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueFbCustomerChat, {
  page_id: 183171851735415, //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'th-TH', // default 'en_US'
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
