<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
const Header = () => import("@/components/layouts/Header");
const Footer = () => import("@/components/layouts/Footer");
export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
}
</script>

<style>

</style>
