import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import("@/views/Home");
const WebApp = () => import("@/views/WebApp");
const WinApp = () => import("@/views/WinApp");
const MobileApp = () => import("@/views/MobileApp");
const Contacts = () => import("@/views/Contacts");
const About = () => import("@/views/About");
const Download = () => import("@/views/Download");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/WebApp",
    name: "WebApp",
    component: WebApp,
  },
  {
    path: "/WinApp",
    name: "WinApp",
    component: WinApp,
  },
  {
    path: "/MobileApp",
    name: "MobileApp",
    component: MobileApp,
  },
  {
    path: "/Contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/Download",
    name: "Download",
    component: Download,
  },
];

const router = new VueRouter({
  // mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
